import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import _ from 'lodash';
import { helpdeskStore, mainStore } from '@/utils/store-accessor';
import SaveButton from '@/components/SaveButton.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import UploadFilesComponent from '@/components/UploadFilesComponent.vue';
import TicketDateDivider from './TicketDateDivider.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { format, parseISO } from 'date-fns';
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.ticket = null;
        this.helpdeskUser = null;
        this.id = null;
        this.messages = [];
        this.anexosUploadFiles = [];
        this.message = '';
        this.loading = false;
        this.dialogAdicionarAnexos = false;
        this.loadingEnviarMensagem = false;
        this.loadingCreateIssue = false;
        this.contrastColors = [
            '#4a1764',
            '#31091a',
            '#670e34',
            '#183326',
            '#570b61',
            '#204f06',
            '#101666',
            '#754404',
            '#47520f',
            '#0d23a1',
            '#990b60',
            '#17542c',
            '#6b4900',
            '#4c4d52',
            '#000000',
            '#333333',
        ];
        this.niveisOptions = [
            { id: 1, value: 'Básico' },
            { id: 2, value: 'Intermediário' },
            { id: 3, value: 'Avançado' },
            { id: 4, value: 'Expert' },
        ];
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get ticketFinalizado() {
        if (!this.ticket) {
            return false;
        }
        return this.ticket.status.toLowerCase() === 'Finalizado'.toLowerCase();
    }
    get groupedTicketLogsByDate() {
        return _.groupBy(this.messages, (t) => format(parseISO(String(t.created_at)), 'yyyy-MM-dd'));
    }
    async subirNivelTicketlistener(item) {
        if (!item) {
            return;
        }
        await helpdeskStore.updateHelpdeskTicket({
            level_id: item,
            id: this.ticket.id,
        });
    }
    colorGenerator(seed) {
        function seededRandom(seed) {
            const x = Math.sin(seed) * 1000;
            return x - Math.floor(x);
        }
        const getContrastingColor = (seed) => {
            const index = Math.floor(seededRandom(seed) * this.contrastColors.length);
            return this.contrastColors[index];
        };
        return getContrastingColor(seed);
    }
    addLineBreaks(item) {
        if (!item) {
            return '';
        }
        return item
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/'/g, '&quot;')
            .replace(/'/g, '&#039;')
            .replace(/\n/g, '<br>');
    }
    async handleCriarIssueGitHub() {
        if (this.ticket.github_issue_url) {
            window.open(this.ticket.github_issue_url, '_blank');
            return;
        }
        let cancel = false;
        let milestone = null;
        await this.$swal({
            icon: 'info',
            title: 'Tipo de Issue',
            confirmButtonText: 'Nova feature',
            denyButtonText: 'Bugs / Erros',
            cancelButtonText: 'Nenhum / Outro',
            showCancelButton: true,
            showDenyButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                milestone = 4;
            }
            else if (result.isDenied) {
                milestone = 3;
            }
            else if (String(result.dismiss) === 'cancel') {
                milestone = null;
            }
            else {
                cancel = true;
            }
        });
        if (cancel) {
            return;
        }
        this.loadingCreateIssue = true;
        this.ticket = await helpdeskStore.createHelpdeskIssueGitHub({
            ticketId: this.ticket.id,
            milestone,
        });
        this.loadingCreateIssue = false;
    }
    async onClickAdicionarAnexosSubmit() {
        this.dialogAdicionarAnexos = false;
        await helpdeskStore.createHelpdeskTicketLogAnexo({
            files: this.anexosUploadFiles,
            id: this.id,
        });
        await this.carregaDadosTicket();
    }
    async finalizar() {
        await helpdeskStore.updateHelpdeskTicket({
            status_id: 5,
            id: this.ticket.id,
        });
        await this.carregaDadosTicket();
    }
    async submit() {
        if (this.message.trim() === '') {
            return;
        }
        this.loadingEnviarMensagem = true;
        await helpdeskStore.createHelpdeskTicketLog({
            id: this.id,
            message: this.message,
        });
        this.message = '';
        this.loadingEnviarMensagem = false;
    }
    async onFileClick(file) {
        const downloaded_file = await helpdeskStore.downloadHelpdeskAnexo({
            fileName: file.file_name,
        });
        const contentType = downloaded_file.headers['content-type'];
        const blob = new Blob([downloaded_file.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${file.file_name}`;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
    }
    async atribuirTicket() {
        const responseData = await helpdeskStore.setHelpdeskClaimTicket({
            id: this.helpdeskUser.id,
            ticket_id: this.ticket.id,
        });
        this.ticket = responseData;
    }
    async carregaDadosTicket() {
        this.ticket = await helpdeskStore.getHelpdeskTicket(this.id);
        if (!this.ticket) {
            return;
        }
        this.messages[0] = {
            created_at: this.ticket.created_at,
            log_user: this.ticket.user,
            log_user_id: this.ticket.user_id,
            message: this.ticket.description,
            ticket_id: this.ticket.id,
        };
        this.messages = this.messages.concat(this.ticket.ticket_logs);
    }
    async mounted() {
        this.loading = true;
        // @ts-ignore
        this.id = this.$router.currentRoute.params.id;
        this.helpdeskUser = await helpdeskStore.getMyHelpdeskUser();
        await this.carregaDadosTicket();
        this.loading = false;
        const socket = new WebSocket(`wss://websocket.knnidiomas.com.br/ws/v1/subscribe/helpdesk/ticket-logs/${this.ticket.id}/`);
        socket.onmessage = (event) => {
            this.messages.push(JSON.parse(event.data));
        };
    }
};
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SaveButton,
            UploadFilesComponent,
            ButtonComponent,
            TicketDateDivider,
        },
    })
], Create);
export default Create;

import { __decorate } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { differenceInDays, format, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';
let DateDivider = class DateDivider extends Vue {
    get formattedDate() {
        const parsedDate = parse(this.stringDate, 'yyyy-MM-dd', new Date());
        const days = differenceInDays(parsedDate, new Date());
        let formattedDate = '';
        switch (true) {
            case days === 0:
                formattedDate += 'Hoje, ';
                break;
            case days === -1:
                formattedDate += 'Ontem, ';
                break;
            default:
                break;
        }
        formattedDate += format(parsedDate, `dd 'de' MMMM, yyyy`, { locale: ptBR });
        return formattedDate;
    }
};
__decorate([
    Prop({ type: String })
], DateDivider.prototype, "stringDate", void 0);
DateDivider = __decorate([
    Component
], DateDivider);
export default DateDivider;
